import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as GatsbyTypes from '../graphqlTypes';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PageTitle from '../components/PageTitle';
import Banner from '../components/Banner';
import CenteredBox, { CenteredBoxSizes } from '../components/CenteredBox';
import { scale } from '../styled';
import ButtonLink from '../components/ButtonLink';
import Grid from '../components/Grid';

const AboutPage: React.FC = () => {
  const { file } = useStaticQuery<GatsbyTypes.GetAboutPageQuery>(
    graphql`
      query GetAboutPage {
        file(name: { eq: "about" }) {
          id
          childMarkdownRemark {
            id
            html
            frontmatter {
              title
              banner {
                childImageSharp {
                  gatsbyImageData(height: 440, layout: FULL_WIDTH)
                }
              }
              image_1 {
                childImageSharp {
                  gatsbyImageData(width: 864, layout: CONSTRAINED)
                }
              }
              image_2 {
                childImageSharp {
                  gatsbyImageData(width: 500, layout: CONSTRAINED)
                }
              }
              seo {
                title
                description
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );

  const seoData = file?.childMarkdownRemark?.frontmatter?.seo;
  /* istanbul ignore next */
  if (!seoData?.title || !seoData.description) {
    throw new Error('[Missing data] Page SEO meta');
  }

  const pageData = file?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.title) {
    throw new Error('[Missing data] Page content');
  }

  const bannerImg = pageData.banner?.childImageSharp?.gatsbyImageData;

  return (
    <Layout>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image?.publicURL}
      />
      <PageTitle>{pageData.title}</PageTitle>
      {bannerImg && <Banner img={bannerImg} />}
      <CenteredBox size={CenteredBoxSizes.S}>
        <p style={{ marginBottom: scale(2) }}>
          À l’origine de notre service de livraison, il y a trois cyclistes aguerris : Axel, Simon
          et Guillaume. Au guidon de leurs vélos à assistance électrique, il parcourent les pistes
          cyclables nantaises toute la journée pour livrer vos plis, colis et courses urgentes dans
          toute l’agglomération nantaise.
        </p>
        <GatsbyImage
          image={pageData.image_1?.childImageSharp?.gatsbyImageData}
          alt=""
          style={{ maxWidth: '100%', borderRadius: scale(2), marginBottom: scale(2) }}
        />
        <h2>Qui sommes-nous ?</h2>
        <p>
          Après s’être rencontrés sur les bancs du collège, chacun a pris une trajectoire différente
          tout en gardant un œil sur les roues des deux autres.
        </p>
        <p>
          La première échappée a vu Simon participer à l&apos;ouverture et au développement
          d&apos;un commerce. Poursuivi de près par Guillaume, récompensé de ses efforts par un
          diplôme en finance.
        </p>
        <p>
          Axel, lui, vise le maillot vert en se spécialisant dans le commerce du bois (il en connait
          un rayon). La course a finalement rassemblé les trois coureurs au pied du col de
          l&apos;entreprenariat (classé hors catégorie).
        </p>
        <p>
          Affrontant les crevaisons, les sauts de chaînes et les intempéries, nos trois champions du
          deux roues aperçoivent la flamme rouge et se mettent à croire en leurs chances de
          victoire. En associant chacun leurs aptitudes, ils ont concrétisé leur ambition par{' '}
          <b>l&apos;aboutissement de Green Course</b>.
        </p>
        <p style={{ marginBottom: scale(4) }}>
          L&apos;écologie est au coeur de nos services :{' '}
          <Link to="/green-philosophie">découvrez notre green philosophie !</Link>
        </p>
      </CenteredBox>
      <CenteredBox>
        <Grid widths={[45, 55]}>
          <React.Fragment>
            <GatsbyImage
              image={pageData.image_2?.childImageSharp?.gatsbyImageData}
              alt=""
              style={{ maxWidth: '100%', borderRadius: scale(2), marginBottom: scale(2) }}
            />
          </React.Fragment>
          <React.Fragment>
            <h2>La livraison par coursier à vélo </h2>
            <p>
              Pour vos plis, petits colis ou envois volumineux, les pieds bien ancrés sur les
              pédales, nous sommes réactifs pour assurer le transport de vos marchandises !
            </p>
            <p>
              Tous les trois associés, nous sommes les dirigeants de l’entreprise mais aussi les
              livreurs, ce qui nous permet d’effectuer nos prestations dans les règles de l’art et
              d’être des interlocuteurs privilégiés lors du passage dans vos locaux. Nous sommes
              soucieux de la marchandise transportée et offrons{' '}
              <b>un service de qualité et de confiance depuis 2013</b>.
            </p>
            <p>Notre mode de livraison rapide et écologique vous séduit ?</p>
            <ButtonLink to="/contact">Contactez-nous</ButtonLink>
          </React.Fragment>
        </Grid>
      </CenteredBox>
    </Layout>
  );
};

export default AboutPage;
